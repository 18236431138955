import React from "react"
import "../../styles/all.scss"
import YouTubeWrapper from "../../components/players/youtube-wrapper"

const DribbleShow = () => {
  return (
    <div className="has-margin-left-10-desktop">
      <YouTubeWrapper youtubeId="MDNJhv73rFM" />
      <YouTubeWrapper youtubeId="cvpNkFpU8xk" />
      <YouTubeWrapper youtubeId="YU5ToP0_ang" />
    </div>
  )
}
export default DribbleShow
