import React from "react"
import "../../styles/all.scss"
import { graphql, useStaticQuery } from "gatsby"

const TenKClub = () => {
  const { allContentfulCavalrySeasons } = useStaticQuery(graphql`
    query TenKClubQuery {
      allContentfulCavalrySeasons {
        edges {
          node {
            _10_001_dribble_club_player {
              name
            }
            season
          }
        }
      }
    }
  `)

  return (
    <div className="has-margin-bottom-35">
      <div className="has-margin-bottom-15">
        Cavalry gives a shout out to the following players who devoted a part of
        their summer to logging at least 10,001 shots (or dribbles for the
        youngest ones)! Can’t wait to see who will commit their time to
        improving their basketball skills!!
      </div>

      {allContentfulCavalrySeasons.edges.map(({ node }) => {
        const { _10_001_dribble_club_player, season } = node
        return _10_001_dribble_club_player ? (
          <table
            className="table is-striped is-half-width-tablet has-margin-bottom-15"
            key={season}
          >
            <thead>
              <tr>
                <th
                  style={{
                    fontSize: "1.25em",
                    paddingLeft: "5px",
                  }}
                >
                  {season}
                </th>
              </tr>
            </thead>
            <tbody>
              {_10_001_dribble_club_player.map(player => {
                return (
                  <tr key={player.name}>
                    <td>{player.name}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : null
      })}
      <h4 className="title has-margin-bottom-15 has-padding-top-25">
        10,001 Summer Program Forms
      </h4>
      <div className="buttons are-medium has-margin-bottom-15">
        <a
          className="button is-primary is-outlined"
          href="/docs/10001DribbleSummerProgram.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Download Dribble Form
        </a>
        <a
          className="button is-primary is-outlined"
          href="/docs/10001ShotSummerProgram.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Download Shot Form
        </a>
      </div>
    </div>
  )
}
export default TenKClub
