import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "../../styles/all.scss"

const Cav75 = () => {
  const { allContentfulCavalrySeasons } = useStaticQuery(graphql`
    query Cav75Query {
      allContentfulCavalrySeasons {
        edges {
          node {
            season
            cavalry_75_player {
              name
              score
            }
          }
        }
      }
    }
  `)

  return (
    <>
      {allContentfulCavalrySeasons.edges.map(({ node }) => {
        const { cavalry_75_player, season } = node
        return cavalry_75_player ? (
          <div className="has-margin-bottom-35" key={season}>
            <table className="table is-striped is-half-width-tablet">
              <thead>
                <tr>
                  <th>{season}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {cavalry_75_player
                  .sort(function(a, b) {
                    return a.score - b.score
                  })
                  .reverse()
                  .map(player => {
                    return (
                      <tr key={player.name}>
                        <td>{player.name}</td>
                        <td>{player.score}</td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        ) : null
      })}
    </>
  )
}

export default Cav75
