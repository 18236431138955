import React from "react"

export default ({ youtubeId }) => {
  return (
    <div className="video">
      <iframe
        src={`https://www.youtube.com/embed/${youtubeId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="dribble show"
      />
    </div>
  )
}
