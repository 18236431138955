import React from "react"
import "../../styles/all.scss"

const Alumni = () => (
  <div className="has-margin-bottom-35">
    <div className="has-margin-bottom-15">
      <p>
        We are so proud to have some of the best athletes and students graduate
        from the Cavalry Basketball Program.
      </p>
      <p>
        Check out this list of Cavalry Alumni Accomplishments and be as amazed
        as we are!
      </p>
    </div>
    <div className="buttons are-medium">
      <a
        className="button is-primary is-outlined"
        href="/docs/Cavalry-Alumni-Accomplishments.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        Cavalry Alumni
      </a>
    </div>
  </div>
)
export default Alumni
