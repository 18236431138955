import React, { useState } from "react"
import "../styles/all.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroBar from "../components/hero-bar"
import Tabs from "../components/tabs/tabs-container"
import Tab from "../components/tabs/tab"
import TabsContent from "../components/tabs/tab-content"
import DribbleShow from "../components/players/dribble-show"
import TenKCLub from "../components/players/club"
import Drills from "../components/players/drills"
import Cav75 from "../components/players/cav-75"
import Alumni from "../components/players/alumni"

const PlayerPage = () => {
  const [activeTab, setActiveTab] = useState("10001")

  return (
    <Layout>
      <SEO title="Players" />
      <HeroBar subtitle="Player Information" />
      <div className="has-background-white-ter has-margin-top-15">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="content">
              <div className="has-margin-top-15 has-margin-15-mobile">
                <div className="tile">
                  <h1 className="title has-padding-bottom-5 why-cav">
                    Players
                  </h1>
                </div>
                <div className="tile">
                  <h3 className="subtitle">
                    We are so glad that you are a part of Cavalry Basketball!
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="columns is-mobile">
        <div className="column is-10-mobile is-offset-1-mobile is-6-desktop is-offset-3-desktop">
          <article className="message is-info has-margin-top-25">
            <div className="message-body is-size-5">
              <div>
                <a
                  href="https://www.signupgenius.com/go/30E0D45ABAB2FABFB6-oct21shooting"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SIGN UP GENIUS
                </a>{" "}
                for shooting.
              </div>
              <div className="has-margin-top-25">
                <a
                  href="https://www.signupgenius.com/go/30E0D45ABAB2FABFB6-cavalry3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SIGN UP GENIUS
                </a>{" "}
                for Cavalry 75.
              </div>
            </div>
          </article>
        </div>
      </div>
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <div className="content has-margin-top-15">
            <Tabs>
              <Tab
                title="10,001 Club"
                activeTab={activeTab === "10001"}
                changeActiveTab={() => {
                  setActiveTab("10001")
                }}
              />
              <Tab
                title="Drills"
                activeTab={activeTab === "drills"}
                changeActiveTab={() => {
                  setActiveTab("drills")
                }}
              />
              <Tab
                title="Dribble Show"
                activeTab={activeTab === "dribble"}
                changeActiveTab={() => {
                  setActiveTab("dribble")
                }}
              />
              <Tab
                title="Cavalry 75 Results"
                activeTab={activeTab === "cav75"}
                changeActiveTab={() => {
                  setActiveTab("cav75")
                }}
              />
              {/* WAITING FOR CONTENT */}
              {/*<Tab*/}
              {/*  title="Cavalry Alumni"*/}
              {/*  activeTab={activeTab === "alumni"}*/}
              {/*  changeActiveTab={() => {*/}
              {/*    setActiveTab("alumni")*/}
              {/*  }}*/}
              {/*/>*/}
            </Tabs>
            <TabsContent>
              {activeTab === "10001" && <TenKCLub />}
              {activeTab === "drills" && <Drills />}
              {activeTab === "dribble" && <DribbleShow />}
              {activeTab === "cav75" && <Cav75 />}
              {activeTab === "alumni" && <Alumni />}
            </TabsContent>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PlayerPage
