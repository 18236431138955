import React from "react"
import "../../styles/all.scss"

const Tab = ({ title, activeTab, changeActiveTab }) => (
  <li className={activeTab ? "is-active" : ""} onClick={changeActiveTab}>
    <a>
      <span>{title}</span>
    </a>
  </li>
)
export default Tab
