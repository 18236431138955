import React from "react"
import "../../styles/all.scss"

const Drills = () => (
  <div className="has-margin-bottom-35">
    <div className="has-margin-bottom-15">
      We will post various drills here for you to work on your technical skills.
      Get better every day!
    </div>
    <h4 className="title has-margin-bottom-15 has-padding-top-15">
      Ball Handling
    </h4>
    <div className="buttons are-medium">
      <a
        className="button is-primary is-outlined"
        href="/docs/daily-dribbling-series.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        Daily Ball Handling Series
      </a>
    </div>
  </div>
)
export default Drills
